<template>
  <div>
    <a-button-group>
      <a-button icon="edit" size="small" @click="updateModalVisible = true">编辑</a-button>
      <a-popconfirm title="确定重置吗? 默认密码: 123456" @confirm="resetPassword">
        <a-button size="small" type="primary" icon="sync">重置密码</a-button>
      </a-popconfirm>
      <a-popconfirm title="确定设置为默认跟进人吗" @confirm="setDefault">
        <a-button size="small" type="primary">设置默认跟进人</a-button>
      </a-popconfirm>
      <a-popconfirm title="确定删除吗" @confirm="destroy">
        <a-button type="danger" icon="delete" size="small">删除</a-button>
      </a-popconfirm>
    </a-button-group>

    <update-form-modal v-model="updateModalVisible" :item="item" @update="update" @cancel="updateModalVisible = false" />
  </div>
</template>

<script>
import { userDestroy, userResetPassword, userSetDefault } from "@/apis/system";

export default {
  components: {
    UpdateFormModal: () => import("./UpdateFormModal"),
  },
  props: ["item"],
  data() {
    return {
      updateModalVisible: false,
    };
  },
  methods: {
    update(item) {
      this.$emit("update", item);
    },
    destroy() {
      userDestroy({ id: this.item.id }).then(() => {
        this.$message.success("删除成功");
        this.$emit("destroy", this.item);
      });
    },
    resetPassword() {
      userResetPassword({ id: this.item.id }).then(() => {
        this.$message.success("重置成功");
      });
    },
    setDefault() {
      userSetDefault({ id: this.item.id }).then(() => {
        this.$message.success("设置成功");
        this.$emit("refresh");
      });
    },
  },
};
</script>

<style scoped></style>
